const items = [
    {
        name: 'Swap',
        url: '/'
    },
    {
        name: 'Explorer',
        url: '/'
    },
    {
        name: 'Documents',
        url: '/'

    },
    {
        name: 'Github',
        url: '/'
    },
]

export default items