import React from 'react'
import columns from '../data/footerLinks';

export default function FooterLinks() {
  return (
    <div className='footer-column'>
      {columns.map(({ title, items }, ind) => (
        <div key={ind}>
          <span className="footer-column__title">
            {title}
          </span>
          <div className="footer-column__links">
            {items.map(({ title, url }, ind) => (
              <a key={ind} href={url} className="footer-column__links-link">
                {title}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}