import { __LOCK, __SWAP, __TARGET } from '../assets/icons';

const smartItems = [
    {
        icon: __LOCK,
        title: 'Non-custodial + MPC',
        content: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua.`
    },
    {
        icon: __SWAP,
        title: 'No-slippage swap',
        content: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua.`
    },
    {
        icon: __TARGET,
        title: 'SmartSwap router',
        content: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua.`
    },
]

export default smartItems