import React from 'react'
import Btn from '../components/Btn'

export default function Info() {
    return (
        <section className='info container'>
            <div className="info__left">
                <h1 className="info__left-title">
                    Provide cross-chain
                    routing protocol
                </h1>
                <h5 className='info__left-info'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                </h5>
                <div className='info__left-btns'>
                    <Btn className='primary' title={'Get started'}/>
                    <Btn className='secondary' title={'Explorer'}/>
                </div>
            </div>
            <div className="info__right">
                <img className="info__right-img" src="./img/block-chain.png" alt="" />
            </div>
        </section>
    )
}
