import React from 'react'
import items from '../data/statistics';

export default function Statistics() {
    return (
        <section className='container'>

            <div className="statistics">
                {items.map(({ name, value }, ind) => (
                    <div key={ind} className="statistics__item">
                        <span className="statistics__item-value">
                           {value}
                        </span>
                        <span className="statistics__item-name">
                            {name}
                        </span>
                    </div>
                ))}
            </div>
        </section>
    )
}
