import React from 'react'

const items = ['visa', 'master', 'bitcoin']

export default function FooterPayment() {
    return (
        <div className='footer-payment'>
            <span className="footer-payment__title">
                We accept following
                payment systems
            </span>
            <div className="footer-payment__items">
                {items.map((item, ind) => (
                    <div key={ind} className="footer-payment__items-item">
                        <img src={`./img/${item}.png`} alt="logo" />
                    </div>
                ))}
            </div>
        </div>
    )
}
