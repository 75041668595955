import React, { useEffect, useState } from 'react'
import items from '../data/headerNav'

const defaultFunc = () => {}

export default function HeaderNav({ show = false, handleShow = defaultFunc , setShow = defaultFunc }) {

  const [resize, setResize] = useState(0)

  useEffect(() => {
    const border = document.querySelector('.header-border__content')
    const activePos = document.querySelector('.header-nav__item.active .header-nav__item-pos')
    if (activePos) {
      border.style.left = `${activePos.getBoundingClientRect().left}px`
    }
  }, [resize])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setResize(res => res + 1)
      if (show && window.innerWidth > 991) {
        setShow(false)
      }
    });
  }, [setShow, setResize, show])

  return (
    <nav className={`header-nav ${show ? 'mobile' : ''}`}>
      {items.map(({ name, url }, ind) => (
        <a onClick={() => { handleShow() }} className={`header-nav__item ${ind === 0 ? 'active' : ''}`} key={ind} href={url}>{name}
          {ind === 0 && <div className='header-nav__item-pos'/>}
        </a>
      ))}
    </nav>
  )
}
