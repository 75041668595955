import React from 'react'
import FooterLinks from '../components/FooterLinks'
import FooterPayment from '../components/FooterPayment'
import socials from '../data/socials';


export default function Footer() {
    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer__content">
                    <div className="footer__content-left">
                        <img src="./img/logo-dark.png" alt="logo" className="footer__content-left__logo" />
                    </div>
                    <div className="footer__content-right">
                        <FooterLinks />
                        <FooterPayment />
                    </div>
                </div>
                <div className="footer__down">
                    <span className="footer__down-title">
                        © 2022 SafeSwap. All rights reserved.
                    </span>
                    <div className="footer__down-socials">
                        {socials.map(({ icon, url }, ind) => (
                            <a key={ind} className="footer__down-socials__item" href={url}>
                                {icon()}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    )
}
