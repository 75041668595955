import React from 'react'
import HeaderNav from '../components/HeaderNav'
import ThemeBtn from '../components/ThemeBtn'
import Btn from '../components/Btn';

export default function MobileMenu({ show, setShow }) {
    const handleShow = () => {
        setShow(show => !show)
    }
    return (
        show &&
        <div className='mobile-menu-parent'>
            <div className="mobile-menu container">
                <HeaderNav setShow={setShow} handleShow={handleShow} show={true} />
                <div className='mobile-menu__items'>
                    <ThemeBtn />
                    <Btn title='Get app' />
                </div>
            </div>
        </div>
    )
}
