import React from 'react'
import { useContext } from 'react'
import themeContext from '../context/theme';
import HeaderNav from '../components/HeaderNav'
import ThemeBtn from '../components/ThemeBtn'
import Btn from '../components/Btn';
import { __BURGER, __CLOSE } from '../assets/icons';

export default function Header({ show, setShow }) {
    const theme = useContext(themeContext)

    const menuHandler = () => {
        setShow(show => !show)
    }

    return (
        <>
            <header className='header'>
                <div className="container">
                    <div className="header__content">
                        <div className="header__content-left">
                            {theme.theme === 'dark' ?
                                <img className="header__content-left__logo" src="./img/logo-dark.png" alt="logo" /> :
                                <img className="header__content-left__logo" src="./img/logo-light.png" alt="logo" />
                            }

                            <HeaderNav />
                        </div>
                        <div className="header__content-right">
                            <ThemeBtn />
                            <Btn title='Get app' />
                            {!show ?
                                <button onClick={() => { menuHandler() }} className="header__content-right__burger">
                                    {__BURGER()}
                                </button>
                                :
                                <button onClick={() => { menuHandler() }} className="header__content-right__burger">
                                    {__CLOSE()}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </header>
            <div className='header-border container'>
                <div className="header-border__content">
                </div>
            </div>
        </>
    )
}
