const stats = [
    {
        name: 'Total Volume',
        value : '$41.92B'
    },
    {
        name: 'Total TVL',
        value : '$9.06B'
    },
    {
        name: 'Tokens',
        value : '1,385'
    },
    {
        name: 'Coins',
        value : '7,560'
    },

]

export default stats