import React from 'react'
import { __TICK } from '../assets/icons'

const items = ['Multichain tokens', 'Free listing', 'Easy integration', 'Quick deployment']

export default function StepMap() {
    return (
        <div className='step'>
            {items.map((item, ind) => (
                <div key={ind} className="step__item">
                    <div className='step__item-icon'>
                        {__TICK()}
                    </div>
                    <span className='step__item-title'>
                        {item}
                    </span>
                </div>
            ))}
        </div>
    )
}
