import React from 'react'
import smartItems from '../data/whySmart';

export default function WhySmart() {
    return (
        <section className='container'>
            <div className="smart">
                <span className='section-title'>
                    Why SmartSwap?
                </span>
                <div className="smart__items">
                    {smartItems.map(({ icon, title, content }, ind) => (
                        <div key={ind} className="smart__items-item">
                            <div className="smart__items-item__icon">
                                {icon()}
                            </div>
                            <span className="smart__items-item__title">
                                {title}
                            </span>
                            <span className="smart__items-item__content">
                                {content}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
