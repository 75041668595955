import React from 'react'
import StepMap from '../components/StepMap'

const count = [1, 1, 1, 1, 1, 1, 1, 1, 1]

export default function Ecosystem() {
    return (
        <section className='container'>
            <div className="ecosystem">
                <div className="ecosystem__left">
                    <span className="ecosystem__left-title">
                        Integrations
                        1385+ Projects
                        and 30 Chains
                    </span>
                    <StepMap />
                </div>
                <div className="ecosystem__right">
                    <span className="ecosystem__right-title">
                        Cross-chain ecosystem
                    </span>
                    <div className="ecosystem__right-items">
                        {count.map((item, ind) => (
                            <div key={ind} className="ecosystem__right-items__item">
                                <img className="ecosystem__right-items__item-img" src="./img/logo/moonbeam.png" alt="crypto logo" />
                                <span className="ecosystem__right-items__item-title">
                                    Spiritswap
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
