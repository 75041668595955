import React from 'react'
import Btn from '../components/Btn'

export default function Chains({data=[] , title , btn = false}) {
    return (
        <section className='container'>
            <div className={`chains ${btn ? 'secondary' : ''}`}>
                <span className="section-title">
                    {title}
                </span>
                <div className="chains__items">
                    {data.map(({ img, title }, ind) => (
                        <div key={ind} className="chains__items-item">
                            <img className='chains__items-item__img' src={img} alt="chains logo" />
                            <span className='chains__items-item__title'>
                                {title}
                            </span>
                        </div>
                    ))}
                </div>
                {btn && <Btn className='secondary' title={'See all here'}/>}
            </div>
        </section>
    )
}
