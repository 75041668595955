const items = [
    {
        title : 'About',
        items : [
            {
                title : 'Blog',
                url : '/'
            },
            {
                title : 'FAQ',
                url : '/'
            },
            {
                title : 'Github',
                url : '/'
            },
            {
                title : 'Docs',
                url : '/'
            },
            {
                title : 'Brand Assets',
                url : '/'
            },
            {
                title : 'Careers',
                url : '/'
            },
        ]
    },
    {
        title : 'Developers',
        items : [
            {
                title : 'Listing',
                url : '/'
            },
            {
                title : 'Support',
                url : '/'
            },
            {
                title : 'Dev docs',
                url : '/'
            },
            {
                title : 'Audit',
                url : '/'
            },
            {
                title : 'Bug bounty',
                url : '/'
            },
            {
                title : 'Contact',
                url : '/'
            },
        ]
    },
    {
        title : 'Product',
        items : [
            {
                title : 'Router',
                url : '/'
            },
            {
                title : 'Explorer',
                url : '/'
            },
            {
                title : 'Network',
                url : '/'
            },
            {
                title : 'Governance',
                url : '/'
            },
            {
                title : 'Dashboard',
                url : '/'
            },
            {
                title : 'Tools',
                url : '/'
            },
        ]
    },
]

export default items