const chainsItem = [
    {
        img : './img/logo/arbitrum.png',
        title: 'Arbitrum'
    },
    {
        img : './img/logo/fantom.png',
        title: 'Fantom'
    },
    {
        img : './img/logo/avalanche.png',
        title: 'Avalanche'
    },
    {
        img : './img/logo/moonbeam.png',
        title: 'Moonbeam'
    },
    {
        img : './img/logo/moonriver.png',
        title: 'Moonriver'
    },
    {
        img : './img/logo/bitcoin.png',
        title: 'Bitcoin'
    },
    {
        img : './img/logo/fusion.png',
        title: 'Fusion'
    },
    {
        img : './img/logo/blocknet.png',
        title: 'Blocknet'
    },
    {
        img : './img/logo/ethereum.png',
        title: 'Ethereum'
    },
    {
        img : './img/logo/arbitrum.png',
        title: 'Arbitrum'
    },
    {
        img : './img/logo/fantom.png',
        title: 'Fantom'
    },
    {
        img : './img/logo/avalanche.png',
        title: 'Avalanche'
    },
    {
        img : './img/logo/moonbeam.png',
        title: 'Moonbeam'
    },
    {
        img : './img/logo/moonriver.png',
        title: 'Moonriver'
    },
    {
        img : './img/logo/bitcoin.png',
        title: 'Bitcoin'
    },
    {
        img : './img/logo/fusion.png',
        title: 'Fusion'
    },
    {
        img : './img/logo/blocknet.png',
        title: 'Blocknet'
    },
    {
        img : './img/logo/ethereum.png',
        title: 'Ethereum'
    },
    {
        img : './img/logo/arbitrum.png',
        title: 'Arbitrum'
    },
    {
        img : './img/logo/fantom.png',
        title: 'Fantom'
    },
    {
        img : './img/logo/avalanche.png',
        title: 'Avalanche'
    },
    {
        img : './img/logo/moonbeam.png',
        title: 'Moonbeam'
    },
]

export default chainsItem