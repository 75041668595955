import { __FACEBOOK, __INSTAGRAM, __TELEGRAM, __TWITTER, __YOUTUBE } from "../assets/icons";

const socials = [
    {
        title: 'facebook',
        icon: __FACEBOOK,
        url: '/'
    },
    {
        title: 'twitter',
        icon: __TWITTER,
        url: '/'
    },
    {
        title: 'telegram',
        icon: __TELEGRAM,
        url: '/'
    },
    {
        title: 'youtube',
        icon: __YOUTUBE,
        url: '/'
    },
    {
        title: 'instagram',
        icon: __INSTAGRAM,
        url: '/'
    },
]

export default socials