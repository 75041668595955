import { useContext, useState } from 'react'
import { __DARK, __LIGHT } from '../assets/icons'
import themeContext from '../context/theme'

export default function ThemeBtn() {
    const theme = useContext(themeContext)
    const [check, setCheck] = useState(theme.theme === 'dark' ? true : false)

    const inputHandler = () => {
        setCheck(check => !check)
        theme.themeHandler()
    }
    return (
        <label className="toggle-control">
            <input onChange={() => { inputHandler() }} type="checkbox" checked={check} />
            <span className="control"></span>
            <span className="theme-icon">
                {__LIGHT('light-icon')}
                {__DARK('dark-icon')}
            </span>
        </label>

    )
}
