import ThemeContext from "./context/theme";
import chainsItem from "./data/chains";
import Chains from "./layouts/Chains";
import Ecosystem from "./layouts/Ecosystem";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import Info from "./layouts/Info";
import Statistics from "./layouts/Statistics";
import WhySmart from "./layouts/WhySmart";
import { useEffect, useState } from 'react';
import MobileMenu from "./layouts/MobileMenu";


function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark')
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    localStorage.setItem('theme', theme)
    document.querySelector('html').dataset.theme = theme
  }, [theme])

  useEffect(() => {
    showMenu ?
      document.querySelector('html').classList.add('active')
      :
      document.querySelector('html').classList.remove('active')
      
  }, [showMenu])

  const themeHandler = () => {
    setTheme(theme => theme === 'dark' ? 'light' : 'dark')
  }

  return (
    <>
      <ThemeContext.Provider value={{ theme, themeHandler }}>
      <Header
          show={showMenu}
          setShow={setShowMenu}
        />
        <MobileMenu
          show={showMenu}
          setShow={setShowMenu}
        />
        <main>
          <Info />
          <Statistics />
          <WhySmart />
          <Chains data={chainsItem} title='Supported chains' />
          <Ecosystem />
          <Chains data={chainsItem} title='1385+ Supported tokens' btn='true' />
        </main>
        <Footer />
      </ThemeContext.Provider>
    </>
  );
}

export default App;
